.phone-container {
    position: relative;
    height: 440px;
    width: 205px;
    .phone {
      width: 100%;
      height: 100%;
      position: absolute;
      right: -174px;
      top: 54px;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      background-color: white;
      .user-bar {
        height: 42px;
        background: #E6E5EA;
        color: #fff;
        padding: 0 8px;
        font-size: 14px;
        font-weight: 600;
        position: absolute;
        bottom: 0;
        z-index: 1;
        border-bottom-left-radius: 22px;
        border-bottom-right-radius: 22px;
        width: 205px;
      }
      .message {
        color: #000;
        clear: both;
        line-height: 16px;
        font-size: 12px;
        padding: 2px;
        word-wrap: break-word;
        z-index: -1;
        .sent {
          max-width: 160px !important;
          max-height: 300px;
          overflow-y: scroll;
          overflow-x: hidden;
          position: absolute;
          bottom: 42px;
          right: 5px;
          padding: 4px 4px;
          background: #0A81FC;
          color: white;
          border-radius: 5px 5px 0px 5px;
          float: right;
          margin: 6px 0 8px;
          .sent-image {
            max-height: 160px !important;
            min-width: 160px !important;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
  .textContainer{
    position: relative;
    margin-top: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inputPhone {
    width: 96%;
    height: 25px;
    border-radius: 13px;
    border: 1px gray;
    padding: 5px;
  }
  .inputPhone:focus {
    outline-style: none;
    border: 1px gray;
  }
  .phoneSendIcon {
    position: absolute;
    right: 10px;
    top:4px;
  }