.file-upload-container {
    width: 100%;
    height: 200px;
    border: 2px dashed #aaa;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  
  .dropzone {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    outline: none;
  }
  
  .dropzone.active {
    border-color: #2196f3;
  }