.phone-container-wp {
    position: relative;
    height: 440px;
    width: 205px;
    .phone-wp {
      width: 100%;
      height: 100%;
      position: absolute;
      right: -174px;
      top: 54px;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      background-image: url("../../../../../../../assets/images/main/wp-background.png");
      .user-bar-wp {
        height: 50px;
        background: #005e54;
        color: #fff;
        padding: 0 8px;
        font-size: 14px;
        font-weight: 600;
        position: relative;
        z-index: 1;
        border-top-left-radius: 28px;
        border-top-right-radius: 28px;
        width: 207px;
        margin-left: -1px;
      }
      .message-wp {
        color: #000;
        clear: both;
        line-height: 16px;
        font-size: 12px;
        padding: 2px;
        word-wrap: break-word;
        z-index: -1;
        .sent-wp {
          max-width: 160px !important;
          max-height: 300px;
          overflow-y: scroll;
          overflow-x: hidden;
          padding: 4px 4px;
          background: #e1ffc7;
          border-radius: 5px 0px 5px 5px;
          float: right;
          margin: 6px 0 8px;
          .sent-image-wp {
            max-height: 160px !important;
            min-width: 160px !important;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
  