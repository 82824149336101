/* Örnek Global CSS */
.Reactour__step {
    padding: 20px !important;
    border-radius: 10px !important;
  }
  
  .Reactour__controls__button {
    font-size: 16px !important;
  }
  
  .Reactour__nav {
    margin-top: -20px !important;
  }
  
  .Reactour__controls {
    justify-content: center !important;
  }
  
  .Reactour__close {
    font-size: 20px !important;
    color: red !important;
  }
  